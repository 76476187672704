<template>
  <div class="app-container">
    <div class="content">
      <el-form ref="mainForm" :rules="rules" :model="tmpData" label-width="5rem" class="form1" :label-position="'left'">

        <p class="nowCompany">当前公司：{{nowCompany}}</p>

        <el-form-item label='账号' prop='phone' class="phone">
            <el-input v-model='tmpData.phone' maxlength="11"  class="rate" placeholder="请输入手机号" disabled/>
        </el-form-item>

        <el-radio-group v-model="tmpData.roleId" class="choiceRadioPer">
          <el-radio label="01" value="01" class="radio">
            <div class="choices">
              <p class="pLabel">管理员</p>
              <p class="label">全部菜单（所有权限）</p>
            </div>
          </el-radio>
          <el-radio label="02" value="02" class="radio">
            <div class="choices">
              <p class="pLabel">普通用户</p>
              <p class="label">无新增账号权限 |无修改账号角色权限 | 无删除账号成员权限</p>
            </div>
          </el-radio>
        </el-radio-group>

        <div class="button">
          <el-button type="primary" class="btn" @click="submitForm" :loading='isLoading'>保存</el-button>
          <el-button class="btn" @click="back">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "editCurrency",
    components:{
    },
    data() {
      return {
        nowCompany: this.getToken('nowCompany'),
        tmpData: {
            roleId:'',
            phone:'',
        },
        rules: {
            phone: [
                { required: true, message: "请输入手机号码" , trigger: 'blur' },
                { pattern: '^1[3|4|5|7|8|9][0-9]{9}$', message: '手机格式不正确', trigger: 'blur' }
            ],
        },

        isLoading : false,   // 提交等待框
        initLoading : false, // 初始化模态框
      };
    },
    methods: {
        submitForm() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    this.isLoading = true;
                    let data = { companyId:this.getToken('companyId') , ...this.tmpData , userId: this.$route.query.id ,};
                    this.$store.dispatch("EditPermissions", data).then(res => {
                        if (res.success) {
                            this.isLoading = false;
                            this.$router.push('/install/permissions')
                        } else {
                            this.isLoading = false;
                            einvAlert.error("提示",res.msg)
                        }
                        }).catch(err => {
                        this.isLoading = false
                    })
                }
            });
        },
        back() {
            this.$router.push('/install/permissions');
        },
        init(){  //初始化内容
            this.initLoading = true
            this.$store.dispatch("FindPermissionsById", {
                companyId: this.getToken('companyId'),
                userId: this.$route.query.id,
                }).then(res => {
                this.initLoading = false
                if (res.success) {
                    this.tmpData = res.data;
                } else {
                    einvAlert.error("提示",res.msg)
                }
            }).catch(err => {
            this.initLoading = false
            })
        },
        },
        mounted(){
            this.init();
        }
    };
</script>

<style lang='less' scoped>
    .content {
        padding: 0 20px;
        height: calc(100%);
        margin: 0 auto;
        background: #fff;
    .title {
        border-bottom: 1px solid #DCDCDC;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        position: relative;
        line-height: 70px;
        margin: 0;
        .btn-item {
            position: absolute !important;
            right: 2%;
            top: 32%;
        }
    }
    }
    .form1{
        padding: 28px 180px 0 28px;
        width: 690px;
        margin: 0 auto;
        .rate{
            width: 320px;
        }
        .choiceRadioPer{
            margin-bottom: 30px;
        }
        .nowCompany{
            font-size: 18px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            color: #333333;
            line-height: 24px;
            text-align: center;
            margin-bottom: 30px;
            font-weight: bold;
        }
        .phone{
            margin-left: 25px;
        }
        .radio{
            width: 600px;
            height: 80px;
            // margin: 18px auto;
            display: block;
            // border: 1px solid #DCDCDC;
            .choices{
                height: 80px;
                display: inline-block;
                width: 485px;
            }
            .pLabel{
                color: #000;
                font-size: 16px;
                margin: 16px 0 16px;
            }
            .label{
                margin: 0;
            }
            p{
                text-align: left;
            }
            p>span{
                margin-right: 42px;
            }
            .iconicon1-17{
                color: #35C4B1;
            }
            .iconicon1-22{
            color: #999999;
            }
        }
    }
    .line{
        text-align: center;
    }

    .button {
        margin: 20px auto 0;
        text-align: center;
        border-top: 1px solid #DCDCDC;
        padding-top: 40px;
        .btn{
            width: 150px;
            height: 40px;
            margin-right: 12px;
        }
    }
</style>
